<template>
  <div>
    <van-nav-bar title="宣传资料" left-arrow @click-left="onClickLeft" />
    <div class="main">
      <div
        class="item"
        @click="toUrl('/publicity', item.name, item.id)"
        v-for="(item, index) in list"
        :key="index"
      >
      <div v-html="item.name"></div>
        <!-- {{ item.name }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Swipe, SwipeItem, NavBar } from "vant";
import { article } from "@/api/apiData";

export default {
  components: {
    [Toast.name]: Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    article.channel.lists({ parent_id: 2 }).then((res) => {
      if (res.code == 1) {
        this.list = res.data;
      } else {
        Toast.fail(res.msg);
      }
    });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    toUrl(u, i, id) {
      this.toChannel(u, i, id);
    },
    toChannel(u, i, id) {
      article.channel.lists({ parent_id: id }).then((res) => {
        if (res.code == 1) {
          if (res.data.length == 0) {
            this.$router.push({
              path: u,
              query: {
                type: i,
                id: id,
              },
            });
          } else {
            this.$router.push({
              path: "/classifyTwo",
              query: {
                id: id,
              },
            });
          }
        } else {
          Toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  margin: 30px;
  .item {
    text-align: center;
    background: linear-gradient(to right, #39a17d, #7dbc4c);
    color: white;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    margin: 25px auto;
    padding: 12px 20px;
  }
}
</style>